<script setup>
import { useHomeStore } from '~/stores/home.store';

const homeStore = useHomeStore();
const categories = ref([]);
categories.value = await homeStore.fetchCategories();
const limitedCategories = ref(categories.value.slice(0, 4));
const showCategories = ref(limitedCategories.value);
const showAllCategories = ref(false);

const changeCategoryView = () => {
  if (!showAllCategories.value) {
    showCategories.value = categories.value
  }else {
    showCategories.value = limitedCategories.value
  }
  showAllCategories.value = !showAllCategories.value;
}
</script>

<template>
  <div class="grid grid-cols-4 gap-3 relative px-4 mb-5">
    <template v-for="category in showCategories">
      <NuxtLink :to="localePath({ path: category.url })" class="flex flex-col justify-center items-center">
        <div class="bg-white rounded-full h-16 w-16 text-center flex justify-center items-center">
          <i :class="category.icon" class="fa-2xl text-indigo-900"></i>
        </div>
        <h4 class="mt-2 text-sm">{{ category.name }}</h4>
      </NuxtLink>
    </template>
    <div class="col-span-4 bg-white p-2 mt-2 rounded-lg flex justify-center items-center">
      <i class="fa-solid fa-grip me-3 text-indigo-900"></i>
      <span @click="changeCategoryView">{{ $t('client.all_categories') }}</span>
    </div>
  </div>
</template>